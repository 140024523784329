const getLineColor = (index) => {
    if (index != 0) {
        if (index % 2 != 0) {
            return '#ffffff';
        } else {
            return 'rgb(230, 230, 230)';
        }
    } else {
        return 'rgb(230, 230, 230)';
    }
}

const str2bool = (value) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
}

export const toolsService = {
    getLineColor,
    str2bool
};