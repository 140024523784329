import { commonService } from '.';
import axios from "axios";
// postAsync
const APIURL = process.env.PUBLIC_URL + "/api";

const updateSkillsEvaluation = (id, body) => {
    return commonService.post('SkillsEvaluation?id=' + id, body);
};

const insertSkillsEvaluation = (body) => {
    return commonService.post('SkillsEvaluation', body);
};

const getValidateDCSkill = (body) => {
    return commonService.getWithParam('SkillsEvaluation/technique', body);
};

const deleteSkillsEvaluation = (id) => {
    return commonService.deleteWithParam('SkillsEvaluation?id=' + id, null);
};

const updateALotSkillsEvaluationAsync = async (body) => {
    // return commonService.put('SkillsEvaluation', body);
    const endpoint = 'SkillsEvaluation';
    const url = `${APIURL}/` + endpoint;
    return await axios.put(url, body);
};

const updateALotTechnicalSkillsEvaluationAsync = async (body) => {
    // return commonService.put('SkillsEvaluation', body);
    const endpoint = 'SkillsEvaluation';
    const url = `${APIURL}/` + endpoint;
    return await axios.put(url, body);
};

const isEaSkillsDataValid = async (idEa, idUser) => {
    // return commonService.getWithParam('SkillsEvaluation/checkData?idEa=' + idEa + '&idUser=' + idUser, null);
    const endpoint = 'SkillsEvaluation/checkData?idEa=' + idEa + '&idUser=' + idUser;
    const url = `${APIURL}/` + endpoint;
    return await axios.get(url, { params: null });
};

const insertALotSkillsEvaluation = (body) => {
    return commonService.post('SkillsEvaluation/insertALot', body);
};

export const skillsEvaluation = {
    updateSkillsEvaluation,
    getValidateDCSkill,
    insertSkillsEvaluation,
    deleteSkillsEvaluation,
    updateALotSkillsEvaluationAsync,
    updateALotTechnicalSkillsEvaluationAsync,
    isEaSkillsDataValid,
    insertALotSkillsEvaluation
};
