import { alertActionsType } from '../../constants';

export const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case alertActionsType.ERROR:
      return {
        type: 'error-fo',
        message: action.message
      };
    case alertActionsType.ERRORBO:
      return {
        type: 'error-bo',
        message: action.message
      };
    case alertActionsType.CLEAR:
      return {};
    default:
      return state
  }
}