import { commonService } from '.';
import axios from "axios";
// postAsync
const APIURL = process.env.PUBLIC_URL + "/api";
const doActionsAsync = async (idUserConnected, idUser, dateAction, comment, isTraining, isFromEa, idLang, data) => {
    const endpoint = 'Boond/actions?idUserConnected=' + idUserConnected + '&idUser=' + idUser + '&dateAction=' + dateAction + '&comment=' + comment + '&isTraining=' + isTraining + '&isFromEa=' + isFromEa + '&idLang=' + idLang + '&data=' + data;
    const url = `${APIURL}/` + endpoint;
    return await axios.post(url, null);
};

const doRatesAsync = async (idUserConnected, idUser, from, body) => {
    const endpoint = 'Boond/rates?idUserConnected=' + idUserConnected + '&idUser=' + idUser + '&from=' + from;
    const url = `${APIURL}/` + endpoint;
    return await axios.post(url, body);
};

const doActions = async (idUserConnected, idUser, dateAction, comment, isTraining, isFromEa, idLang, data) => {
    return commonService.post('Boond/actions?idUserConnected=' + idUserConnected + '&idUser=' + idUser + '&dateAction=' + dateAction + '&comment=' + comment + '&isTraining=' + isTraining + '&isFromEa=' + isFromEa + '&idLang=' + idLang + '&data=' + data, null);
};

const doRates = async (idUserConnected, idUser, from, body) => {
    return commonService.post('Boond/rates?idUserConnected=' + idUserConnected + '&idUser=' + idUser + '&from=' + from, body);
};

const doHighRates = (idUserConnected, idUser, body) => {
    return commonService.post('Boond/highRates?idUserConnected=' + idUserConnected + '&idUser=' + idUser, body);
};

const doHighRatesAsync = async (idUserConnected, idUser, body) => {
    const endpoint = 'Boond/highRates?idUserConnected=' + idUserConnected + '&idUser=' + idUser;
    const url = `${APIURL}/` + endpoint;
    return await axios.post(url, body);
};

const uploadFileEAToBoond = (reference, idUser, year, idLang) => {
    return commonService.post('Boond/uploadFileToBoondEA?reference=' + reference + '&idUser=' + idUser + '&year=' + year + '&idLang=' + idLang);
};

const uploadFileDCToBoond = (reference, idDc, idLang, brand) => {
    return commonService.post('Boond/uploadFileToBoondDC?reference=' + reference + '&idDc=' + idDc + '&idLang=' + idLang + '&brand=' + brand);
};

const uploadFileDCToBoondAsync = async (reference, idDc, idLang, brand) => {
    const endpoint = 'Boond/uploadFileToBoondDC?reference=' + reference + '&idDc=' + idDc + '&idLang=' + idLang + '&brand=' + brand;
    const url = `${APIURL}/` + endpoint;
    return await axios.post(url, null);
};

const getUserInfo = (idUser) => {
    return commonService.getWithParam('Boond/userInfo?idUser=' + idUser);
};

const sendEmailErrorSendBoond = async (idUserConnected, idUser, apiLabel, error) => {
    const endpoint = 'Boond/sendEmailErrorSendBoond?idUserConnected=' + idUserConnected + '&idUser=' + idUser + '&apiLabel=' + apiLabel + '&error=' + error;
    const url = `${APIURL}/` + endpoint;
    return await axios.post(url, null);
}

export const boondService = {
    doActions,
    doRates,
    doHighRates,
    uploadFileEAToBoond,
    uploadFileDCToBoond,
    getUserInfo,
    doActionsAsync,
    doRatesAsync,
    uploadFileDCToBoondAsync,
    doHighRatesAsync,
    sendEmailErrorSendBoond
};