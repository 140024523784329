import { FC, useState, createContext } from 'react';
import { skillsEvaluation } from 'src/services';
import { SkillEvaluation } from 'src/models/skill/skillEvaluation.model';
import { useTranslation } from 'react-i18next';
import { CURRENT_USER } from 'src/constants';
import { useContext } from 'react';
import { EaContext } from 'src/contexts/EaContext';

type SkillsBehaviorContext = {
	setSkillsBehavior: () => any;
	setSkillsBehaviorDataContext: (dataValue) => void;
	skillsBehaviorDataKO: boolean;
	setSkillsBehaviorDataKOContext: (dataValue) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkillsBehaviorContext = createContext<SkillsBehaviorContext>(
	{} as SkillsBehaviorContext
);

export const SkillsBehaviorProvider: FC = ({ children }) => {
	const {
		idUser
	} = useContext(EaContext);
	const userData = JSON.parse(localStorage.getItem(CURRENT_USER));
	const [skillsBehaviorData, setSkillsBehaviorData] = useState<SkillEvaluation[]>(null);
	// check data
	const [skillsBehaviorDataKO, setSkillsBehaviorDataKO] = useState<boolean>(false);
	const setSkillsBehaviorDataKOContext = (dataValue) => {
		setSkillsBehaviorDataKO(dataValue);
	};
	const setSkillsBehaviorDataContext = (dataValue) => {
		// check data temp
		if (dataValue == null || dataValue.length == 0) {
			setSkillsBehaviorDataKO(false);
		}
		setSkillsBehaviorData(dataValue);
		// check data
		for (let i = 0; i < dataValue.length; i++) {
			if (idUser != 0) {
				if (idUser == userData.id) { // consultant
					if (dataValue[i].noteConsultant == 0 || dataValue[i].noteConsultant == null) {
						setSkillsBehaviorDataKO(true);
						break;
					} else {
						setSkillsBehaviorDataKO(false);
					}
				} else { // manager
					if (dataValue[i].noteManager == 0 || dataValue[i].noteManager == null) {
						setSkillsBehaviorDataKO(true);
						break;
					} else {
						setSkillsBehaviorDataKO(false);
					}
				}
			}
		}
	};
	const setSkillsBehavior = async () => {
		let errorMessage = "";
		if (skillsBehaviorData != null) {
			await skillsEvaluation.updateALotSkillsEvaluationAsync(skillsBehaviorData).then(response => {
				if (!response.data.success) {
					errorMessage = '- ' + response.data.message;
				}
			}).catch(err => {
				errorMessage = '- ' + err.response.data.message;
			});
		}
		return errorMessage;
	}
	const { t } = useTranslation();
	return (
		<>
			<SkillsBehaviorContext.Provider value={{
				setSkillsBehavior,
				setSkillsBehaviorDataContext,
				skillsBehaviorDataKO,
				setSkillsBehaviorDataKOContext
			}}>
				{children}
			</SkillsBehaviorContext.Provider>
		</>
	);
};
