import { FC, useState, createContext } from 'react';
import { skillsEvaluation } from 'src/services';
import { SkillEvaluation } from 'src/models/skill/skillEvaluation.model';
import { useTranslation } from 'react-i18next';
import { CURRENT_USER } from 'src/constants';
import { useContext } from 'react';
import { EaContext } from 'src/contexts/EaContext';

type SkillsMetierFonctionnelleContext = {
    skillsMetierFonctionnelleData: SkillEvaluation[];
    setSkillsMetierFonctionnelle: () => any;
    setSkillsMetierFonctionnelleDataContext: (dataValue) => void;
    skillsMetierFonctionnelleDataKO: boolean;
    setSkillsMetierFonctionnelleDataKOContext: (dataValue) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkillsMetierFonctionnelleContext = createContext<SkillsMetierFonctionnelleContext>(
    {} as SkillsMetierFonctionnelleContext
);

export const SkillsMetierFonctionnelleProvider: FC = ({ children }) => {
    const {
        idUser
    } = useContext(EaContext);
    const userData = JSON.parse(localStorage.getItem(CURRENT_USER));
    const [skillsMetierFonctionnelleData, setSkillsMetierFonctionnelleData] = useState<SkillEvaluation[]>(null);
    // check data
    const [skillsMetierFonctionnelleDataKO, setSkillsMetierFonctionnelleDataKO] = useState<boolean>(false);
    const setSkillsMetierFonctionnelleDataKOContext = (dataValue) => {
        setSkillsMetierFonctionnelleDataKO(dataValue);
    };

    const setSkillsMetierFonctionnelleDataContext = (dataValue) => {
        // check data temp
        if (dataValue == null || dataValue.length == 0) {
            setSkillsMetierFonctionnelleDataKO(false);
        }
        // 
        setSkillsMetierFonctionnelleData(dataValue);
        // check data
        for (let i = 0; i < dataValue.length; i++) {
            if (idUser != 0) {
                if (idUser == userData.id) { // consultant
                    if (dataValue[i].noteConsultant == 0 || dataValue[i].noteConsultant == null) {
                        setSkillsMetierFonctionnelleDataKO(true);
                        break;
                    } else {
                        setSkillsMetierFonctionnelleDataKO(false);
                    }
                } else { // manager
                    if (dataValue[i].noteManager == 0 || dataValue[i].noteManager == null) {
                        setSkillsMetierFonctionnelleDataKO(true);
                        break;
                    } else {
                        setSkillsMetierFonctionnelleDataKO(false);
                    }
                }
            }
        }
    };
    const setSkillsMetierFonctionnelle = async () => {
        let errorMessage = "";
        if (skillsMetierFonctionnelleData != null) {
            await skillsEvaluation.updateALotSkillsEvaluationAsync(skillsMetierFonctionnelleData).then(response => {
                if (!response.data.success) {
                    errorMessage = '- ' + response.data.message;
                }
            }).catch(err => {
                errorMessage = '- ' + err.response.data.message;
            });
        }
        return errorMessage;
    }
    const { t } = useTranslation();
    return (
        <>
            <SkillsMetierFonctionnelleContext.Provider value={{
                skillsMetierFonctionnelleData,
                setSkillsMetierFonctionnelle,
                setSkillsMetierFonctionnelleDataContext,
                skillsMetierFonctionnelleDataKO,
                setSkillsMetierFonctionnelleDataKOContext
            }}>
                {children}
            </SkillsMetierFonctionnelleContext.Provider>
        </>
    );
};
