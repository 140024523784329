import { CURRENT_USER } from "src/constants";
import { AccessByRole, AccessPerPage } from "src/models/access.model";
import { User } from "src/models/user/user.model";

export function userHasAccessToPage(functionnality: string) {
  const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER)) || null;
  if (userData === null) return false;
  if (functionnality === AccessPerPage.menuDc) {
    return (userData.role.id < 3);  // candidat, consultant et TM
  }
  if (functionnality === AccessPerPage.menuEa) {
    console.log("CURRENT USER ===> " + JSON.stringify(userData));
    let tempRole = (userData.role.id === 1 || userData.role.id === 2); // consultant, TM
    return tempRole && (userData.boondProfile !== "Sous Traitant");
  }
  if (functionnality === AccessPerPage.ficheDc) {
    return (userData.role.id !== 7);  // tout sauf support
  }
  if (functionnality === AccessPerPage.createDc) {
    return (userData.role.id < 4 || userData.role.id === 8);  // candidat, consultant, TM, Manager et recruteur
  }
  if (functionnality === AccessPerPage.ficheEa) {
    let tempRoleAccess = (userData.role.id > 0 && userData.role.id < 7);  // Tout sauf candidat et support
    return tempRoleAccess && (userData.boondProfile !== "Sous Traitant");
  }
  if (functionnality === AccessPerPage.menuManagement) {
    return (userData.role.id > 2 && userData.role.id !== 7);  // manager, direction, DPO, RH
  }
  if (functionnality === AccessPerPage.managementCollaborators) {
    return (userData.role.id > 1 && userData.role.id !== 7);  // manager, TM, direction, DPO, RH
  }
  if (functionnality === AccessPerPage.managementCandidats) {
    return (userData.role.id === 3 || userData.role.id === 8);  // manager, recruteur
  }
  if (functionnality === AccessPerPage.managementSkills) {
    return (userData.role.id > 3 && userData.role.id < 7);  // direction, DPO, RH
  }
  if (functionnality === AccessPerPage.managementAdministration) {
    return (userData.role.id > 3 && userData.role.id < 7);  // direction, DPO, RH
  }
  // about exportCertifications
  if (functionnality === AccessPerPage.exportCertifications) {
    console.log("about exportCertifications ===> " + userData.idRoleInternal);
    return (userData.idRoleInternal == 1 || userData.idRoleInternal == 2);  // Admin, Export
  }
  // 
  return false;
}

export function userHasPermission(functionnality: string) {
  const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER)) || null;
  if (userData === null) return false;
  // dc
  if (functionnality === AccessByRole.updateDc) {
    return (userData.role.id !== 7);  // Tout sauf direction et support
  }
  if (functionnality === AccessByRole.updateSubmittedDc) {
    return (userData.role.id === 3 || userData.role.id === 5 || userData.role.id === 6 || userData.role.id === 8);  // manager, dpo, rh, recruiter
  }
  if (functionnality === AccessByRole.updateValidatedDc) {
    return (userData.role.id === 3 || userData.role.id === 5 || userData.role.id === 6 || userData.role.id === 8);  // manager, dpo, rh, recruiter
  }
  if (functionnality === AccessByRole.submitDc) {
    return (userData.role.id !== 7);  // Tout sauf direction et support
  }
  if (functionnality === AccessByRole.validateDc) {
    return (userData.role.id === 3 || userData.role.id === 5 || userData.role.id === 6 || userData.role.id === 8);  // manager, dpo, rh, recruiter
  }
  if (functionnality === AccessByRole.all) {
    return (userData.role.id > 4 && userData.role.id !== 7);  // dpo, rh, recruiter
  }
  // ea
  if (functionnality === AccessByRole.updateEa) {
    return (userData.role.id > 0 && userData.role.id !== 7);  // Tout sauf candidat et support
  }
  //
  if (functionnality === AccessByRole.updateValidatedEa) {
    return (userData.role.id === 6);
  }
  return false;
}