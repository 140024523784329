import { FC, useState, createContext, useRef } from 'react';

type ActionContext = {
  actionToggle: any;
  toggleAction: () => void;
  skillOther: any;
  setSkillOtherContext: (dataValue) => void;
  errorOtherText: any;
  setErrorOtherTextContext: (dataValue) => void;
  errorOtherPerTypeText: any;
  setErrorOtherPerTypeTextContext: (dataValue) => void;
  isUserHimSelf: any;
  isTeamManager: any;
  isManager: any;
  setIsUserHimSelfContext: (dataValue) => void;
  setIsTeamManagerContext: (dataValue) => void;
  setIsManagerContext: (dataValue) => void;
  hasManagerPermission: any;
  setHasManagerPermissionContext: (dataValue) => void;

  hasManagerPermissionResendBoond: any;
  setHasManagerPermissionResendBoondContext: (dataValue) => void;
  saveDcAutoRef: any;
};

export const ActionContext = createContext<ActionContext>(
  {} as ActionContext
);

export const ActionProvider: FC = ({ children }) => {
  const [actionToggle, setActionToggle] = useState(false);
  const [isUserHimSelf, setIsUserHimSelf] = useState(false);
  const [isTeamManager, setIsTeamManager] = useState(false);
  const [hasManagerPermission, setHasManagerPermission] = useState(false);
  const [hasManagerPermissionResendBoond, setHasManagerPermissionResendBoond] = useState(false);
  const [isManager, setIsManager] = useState(false);

  const setIsUserHimSelfContext = (dataValue) => {
    setIsUserHimSelf(dataValue)
  }

  const setIsTeamManagerContext = (dataValue) => {
    setIsTeamManager(dataValue)
  }

  const setHasManagerPermissionContext = (dataValue) => {
    setHasManagerPermission(dataValue);
  }

  const setHasManagerPermissionResendBoondContext = (dataValue) => {
    setHasManagerPermissionResendBoond(dataValue);
  }

  const setIsManagerContext = (dataValue) => {
    setIsManager(dataValue)
  }

  const toggleAction = () => {
    setActionToggle(!actionToggle);
  };

  const [skillOther, setSkillOther] = useState("");
  const [errorOtherText, setErrorOtherText] = useState("");
  const [errorOtherPerTypeText, setErrorOtherPerTypeText] = useState("");

  const setSkillOtherContext = (dataValue) => {
    setSkillOther(dataValue)
  }

  const setErrorOtherTextContext = (dataValue) => {
    setErrorOtherText(dataValue)
  }

  const setErrorOtherPerTypeTextContext = (dataValue) => {
    setErrorOtherPerTypeText(dataValue)
  }

  const saveDcAutoRef = useRef(() => { });

  return (
    <ActionContext.Provider value={{
      actionToggle,
      toggleAction,
      skillOther,
      setSkillOtherContext,
      errorOtherText,
      setErrorOtherTextContext,
      errorOtherPerTypeText,
      setErrorOtherPerTypeTextContext,
      isUserHimSelf,
      isTeamManager,
      isManager,
      setIsUserHimSelfContext,
      setIsTeamManagerContext,
      setIsManagerContext,
      hasManagerPermission,
      setHasManagerPermissionContext,
      hasManagerPermissionResendBoond,
      setHasManagerPermissionResendBoondContext,
      saveDcAutoRef
    }}
    >
      {children}
    </ActionContext.Provider>
  );
};
