import axios from "axios";
import { TOKEN_USER, TOKEN_USER_ORI } from 'src/constants';

const APIURL = process.env.PUBLIC_URL + "/api";

axios.interceptors.request.use((config) => {
    config.headers = getHeaders(config.url);
    return config;
}, error => {
    return Promise.reject(error);
});

const getHeaders = (url = null) => {
    let token = url.includes("Auth/backtomyaccount") ? localStorage.getItem(TOKEN_USER_ORI) : localStorage.getItem(TOKEN_USER);
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': token ? 'Bearer ' + token : 'No Auth',
        'headers': 'application/json'
    }
    return headers;
}

const getWithParam = (endpoint, body) => {
    const url = `${APIURL}/` + endpoint;

    return axios.get(url, { params: body });
}

const get = (endpoint, filter = null) => {
    const url = `${APIURL}/` + endpoint;
    return axios.get(url);
}

const post = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, data);
}

const postBodyWithParam = (endpoint, body, param) => {
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, body, { params: param });
}

const postWithParam = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, null, { params: data });
}

const put = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.put(url, data);
}

const putBodyWithParam = (endpoint, body, param) => {
    const url = `${APIURL}/` + endpoint;
    return axios.put(url, body, { params: param });
}

const putWithParam = (endpoint, body) => {
    const url = `${APIURL}/` + endpoint;

    return axios.put(url, null, { params: body });
}

const deleteWithParam = (endpoint, body) => {
    const url = `${APIURL}/` + endpoint;

    return axios.delete(url, { data: body });
}

const deleteBodyWithParam = (endpoint, body, param) => {
    const url = `${APIURL}/` + endpoint;
    return axios.delete(url, body, { params: param });
}
// async 
const postAsync = async (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return await axios.post(url, data);
}
// 
export const commonService = {
    getWithParam,
    get,
    post,
    postWithParam,
    postBodyWithParam,
    put,
    putWithParam,
    putBodyWithParam,
    deleteWithParam,
    deleteBodyWithParam,
    postAsync
};
