import { FC, useState, createContext } from 'react';
import { keyPeopleService, boondService } from 'src/services';
import { Trans, useTranslation } from 'react-i18next';
import ASMAlert from 'src/components/Alert';
import { Mission } from 'src/models/mission.model';
import { KpDcSkills } from 'src/models/kpDcSkills.model';
import { CURRENT_USER } from 'src/constants';
import DialogBoondError from 'src/components/Dialog/FicheEA/DialogBoondError';
import SuspenseLoader from 'src/components/SuspenseLoader';

type KeyPeopleContext = {
    keyPeopleData: any;
    setKeyPeopleDataContext: (dataValue) => void;
    missionData: any;
    setMissionDataContext: (dataValue) => void;
    setPositionDataContext: (dataValue) => void;
    setDcSkillsDataContext: (dataValue) => void;
    saveKeyPeople: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyPeopleContext = createContext<KeyPeopleContext>(
    {} as KeyPeopleContext
);

export const KeyPeopleProvider: FC = ({ children }) => {
    const { t } = useTranslation();
    const userData = JSON.parse(localStorage.getItem(CURRENT_USER));
    const { REACT_APP_NOTIFICATION_DURATION } = process.env;

    const [displayAlert, setDisplayAlert] = useState<boolean>(false);
    const [displayAlertError, setDisplayAlertError] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>(t('action.success'));
    const [errorMessage, setErrorMessage] = useState<string>(t('action.error'));
    const [errorMessageBoond, setErrorMessageBoond] = useState<string>(t('action.errorValidationAndBoond'));
    const [displayBoondError, setDisplayBoondError] = useState<boolean>(false);

    const [keyPeopleData, setKeyPeopleData] = useState({
        id: 0,
        kptEvalCapacity: false,
        kptTechContributor: false,
        kptArchitectCapacity: false,
        kptRecruitQuality: false,
        kptConceptionTraining: false,
        kptPreSale: false,
        kptComment: '',
        kpmissionComment: '',
        kpmissionRsiComment: '',
        kphpoComment: ''
    });

    const [missionData, setMissionData] = useState<Mission[]>([]);
    const [positionData, setPositionData] = useState<Mission[]>([]);
    const [dcSkillsData, setDcSkillsData] = useState<KpDcSkills[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const setKeyPeopleDataContext = (dataValue) => {
        setKeyPeopleData(dataValue);
    };

    const setMissionDataContext = (dataValue) => {
        setMissionData(dataValue);
    };

    const setPositionDataContext = (dataValue) => {
        setPositionData(dataValue);
    }

    const setDcSkillsDataContext = (dataValue) => {
        setDcSkillsData(dataValue)
    }

    const callApiBood = async () => {
        let successHighRates = false;
        let successRates = false;

        let messageHighRates = "";
        let messageRates = "";
        // begin rates
        console.log("1-begin")
        await boondService.doRatesAsync(userData.id, keyPeopleData.id, 'kp', dcSkillsData).then(async res => {
            if (res.data.data.success) {
                successRates = true;
                console.log("1-ok")
            } else {
                successRates = false;
                messageRates = res.data.data.statusCode + " " + res.data.data.message;
                console.log("1-ko")
                // begin send mail error
                await boondService.sendEmailErrorSendBoond(userData.id, keyPeopleData.id, "rates", messageRates).then(res => {
                    console.log("send mail")
                }).catch(err => {
                    console.log("Send mail error : " + err)
                });
                // end send mail error
            }
        }).catch(async err => {
            successRates = false;
            messageRates = err.response.status + " " + err.response.data.message;
            console.log("1-catch")
            // begin send mail error
            await boondService.sendEmailErrorSendBoond(userData.id, keyPeopleData.id, "rates", messageRates).then(res => {
                console.log("send mail")
            }).catch(err => {
                console.log("Send mail error : " + err)
            });
            // end send mail error
        });
        // end rates
        // begin highRates
        console.log("2-begin")
        await boondService.doHighRatesAsync(userData.id, keyPeopleData.id, dcSkillsData).then(async res => {
            if (res.data.data.success) {
                successHighRates = true;
                console.log("2-ok")
            } else {
                successHighRates = false;
                messageHighRates = res.data.data.statusCode + " " + res.data.data.message;
                console.log("2-ko")
                // begin send mail error
                await boondService.sendEmailErrorSendBoond(userData.id, keyPeopleData.id, "highRates", successHighRates).then(res => {
                    console.log("send mail")
                }).catch(err => {
                    console.log("Send mail error : " + err)
                });
                // end send mail error
            }
        }).catch(async err => {
            successHighRates = false;
            messageHighRates = err.response.status + " " + err.response.data.message;
            console.log("2-catch")
            // begin send mail error
            await boondService.sendEmailErrorSendBoond(userData.id, keyPeopleData.id, "highRates", successHighRates).then(res => {
                console.log("send mail")
            }).catch(err => {
                console.log("Send mail error : " + err)
            });
            // end send mail error
        });
        // end highRates
        setLoading(false);
        console.log("fini")
        if (successHighRates && successRates) {
            setSuccessMessage('- ' + t('action.success') + '&&' + t('action.successBoond'))
            setDisplayAlert(true);
            setTimeout(() => {
                setDisplayAlert(false);
            }, parseInt(REACT_APP_NOTIFICATION_DURATION) * 1000);
            setTimeout(() => {
                window.location.reload();
            }, parseInt(REACT_APP_NOTIFICATION_DURATION) * 1000);
        } else {
            setErrorMessageBoond(messageHighRates + " " + messageRates)
            setDisplayBoondError(true)
        }
    }

    const saveKeyPeople = () => {
        setLoading(true);
        let request = {
            userInfo: {
                id: keyPeopleData.id,
                kptEvalCapacity: keyPeopleData.kptEvalCapacity,
                kptTechContributor: keyPeopleData.kptTechContributor,
                kptArchitectCapacity: keyPeopleData.kptArchitectCapacity,
                kptRecruitQuality: keyPeopleData.kptRecruitQuality,
                kptConceptionTraining: keyPeopleData.kptConceptionTraining,
                kptPreSale: keyPeopleData.kptPreSale,
                kptComment: keyPeopleData.kptComment,
                kpmissionComment: keyPeopleData.kpmissionComment,
                kpmissionRsiComment: keyPeopleData.kpmissionRsiComment,
                kphpoComment: keyPeopleData.kphpoComment
            },
            missionData: missionData,
            allPosition: positionData,
            dcSkillsData: dcSkillsData
        }

        keyPeopleService.updateKeyPeopleData(userData.id, request).then(response => {
            if (response.data.success) {

                if (dcSkillsData != null) {
                    if (dcSkillsData.length != 0) {
                        callApiBood(); // boond
                    } else {
                        setLoading(false);
                        setSuccessMessage(t('action.success'))
                        setDisplayAlert(true);
                        setTimeout(() => {
                            setDisplayAlert(false);
                        }, parseInt(REACT_APP_NOTIFICATION_DURATION) * 1000);
                    }
                } else {
                    setLoading(false);
                    setSuccessMessage(t('action.success'))
                    setDisplayAlert(true);
                    setTimeout(() => {
                        setDisplayAlert(false);
                    }, parseInt(REACT_APP_NOTIFICATION_DURATION) * 1000);
                }
            } else {
                setLoading(false);
                setErrorMessage(t('action.errorValidationAndBoond'))
                setDisplayAlertError(true);
            }
        }).catch(err => {
            setLoading(false);
            setErrorMessage(t('action.errorValidationAndBoond'))
            setDisplayAlertError(true);
        });
    }
    const closeDialogBoondError = () => {
        setDisplayBoondError(false)
        window.location.reload();
    }
    return (
        <>
            {loading && (
                <>
                    <SuspenseLoader />
                </>
            )}
            <DialogBoondError
                message={t('action.messageSendBoondKoKeyPeople')}
                display={displayBoondError}
                handleClose={closeDialogBoondError}
                details={errorMessageBoond}
            />
            <ASMAlert message={successMessage} severity="success" open={displayAlert}
                withClose
                handleCloseAlert={() => {
                    setDisplayAlert(false)
                }}
            />
            <ASMAlert message={errorMessage} severity="error" open={displayAlertError}
                withClose
                handleCloseAlert={() => {
                    setDisplayAlertError(false)
                }}
            />
            <KeyPeopleContext.Provider value={{
                keyPeopleData,
                setKeyPeopleDataContext,
                missionData,
                setMissionDataContext,
                setPositionDataContext,
                setDcSkillsDataContext,
                saveKeyPeople,
            }}>
                {children}
            </KeyPeopleContext.Provider>
        </>
    );
};
