
import { FC, ReactNode, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';

interface SidebarLayoutProps {
  children?: ReactNode;
};

const containsText = (text: string, searchText: string) => {
  return text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {


  const [location, setLocation] = useState(useLocation().pathname);
  const [titleData, setTitleData] = useState<string>();

  useEffect(() => {
    if (containsText(location, 'dc/fiche')) {
      setTitleData("header.titleDc");
    } else if (containsText(location, 'ea/fiche')) {
      setTitleData("header.titleEa");
    } else {
      setTitleData(" ");
    }
  }, [location]);
  return (
    <>
      {/* <Sidebar /> */}
      <MainWrapper>
        {titleData && <Header headerAddon={titleData} />}
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarLayout;
