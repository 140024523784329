import { combineReducers } from 'redux';
import { authenticationReducer } from './auth.reducer';
import { alertReducer } from './alert.reducer';

const RootReducer = combineReducers({
  authenticationReducer,
  alertReducer
});

export default RootReducer;
