import { commonService } from '.';

const getAll = () => {
  return commonService.get('Brand/all');
};
const getAllBrandWithCurrent = (body) => {
  return commonService.getWithParam('Brand/allWithCurrent', body);
};

export const brandService = {
  getAll,
  getAllBrandWithCurrent
};
