import { commonService } from '.';

const getListMyConsultant = (body) => {
    return commonService.getWithParam('ListMyConsultant/all', body);
};

const getListeRechercheMyCollab = (body) => {
    return commonService.getWithParam('ListMyConsultant/recherche', body);
};

const getListeRechercheTypeMyCollab = (body) => {
    return commonService.getWithParam('ListMyConsultant/filtreType', body);
};

const getListeEaImminent = (body) => {
    return commonService.getWithParam('ListMyConsultant/ea', body);
};

const getListeDcAValider = (body) => {
    return commonService.getWithParam('ListMyConsultant/dcAValider', body);
};

const getListeEaAValider = (body) => {
    return commonService.getWithParam('ListMyConsultant/eaAValider', body);
};

const exportListeEa = (body) => {
    return commonService.post('ListMyConsultant/exportExcel', body);
};

const updateEaEcheanceUser = (reference, dateEcheance, nextEaYear) => {
    return commonService.put('ListMyConsultant/updateEaEcheanceUser?reference=' + reference + '&dateEcheance=' + dateEcheance + '&nextEaYear=' + nextEaYear);
};

export const listeMyConsultantService = {
    getListMyConsultant,
    getListeRechercheMyCollab,
    getListeRechercheTypeMyCollab,
    getListeEaImminent,
    getListeDcAValider,
    getListeEaAValider,
    exportListeEa,
    updateEaEcheanceUser
};
