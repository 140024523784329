import { Grid, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from '@mui/icons-material/Warning';
import { Trans, useTranslation } from 'react-i18next';

function DialogBoondError(props) {
	const { t } = useTranslation();

	return (
		<Dialog open={props.display} maxWidth={'md'} fullWidth>
			<DialogTitle style={{ fontSize: 20, paddingBottom: 8, paddingTop: 8, backgroundColor: 'orange', color: '#FFF' }}>
				<Grid container direction="row">
					<Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
						<WarningIcon style={{ fontSize: 20 }} />
						&nbsp;&nbsp;{t('warning')}
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent style={{ paddingBottom: 5, paddingTop: 5 }}>
				<Grid container direction="row" alignItems="stretch" spacing={1} >
					<Grid item xs={12} style={{ fontSize: 18, fontWeight: 500 }}>
						<p>{props.message}</p>
						<p><Trans>action.detailLabel</Trans>{props.details}</p>
						<p>{props.guide}</p>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions style={{ justifyContent: "flex-start", paddingLeft: 24, paddingBottom: 16 }}>
				<Button
					onClick={props.handleClose}
					style={{ minWidth: 100, backgroundColor: 'black', color: 'white' }}
				>
					<Trans>ok</Trans>
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DialogBoondError;