import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import languageEN from '../localization/en.json';
import languageFR from '../localization/fr.json';
import { CURRENT_LANG } from 'src/constants';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      fr: languageFR
    },
    /* default language when load the website in browser */
    lng: localStorage.getItem(CURRENT_LANG) || process.env.REACT_APP_DEFAULTLANGUAGE,
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: process.env.REACT_APP_DEFAULTLANGUAGE,
    /* debugger For Development environment */
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: false
    }
  })

export default i18n;