import { Box, Container, Card, TextField, Button, Typography, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ButtonLoginMSAL from 'src/components/Button/MsalButton';
import { useState, useEffect, Fragment } from 'react';
import { authService } from 'src/services';
import { User } from 'src/models/user/user.model';
import { Trans, useTranslation } from 'react-i18next';
import SuspenseLoader from 'src/components/SuspenseLoader';
import AzureAD, { AuthenticationState } from 'react-aad-msal';
import { authProvider } from 'src/services/auth.provider';
import { store } from 'src/redux/store';
import { authActions } from 'src/redux/actions';
import { useAppDispatch } from 'src/redux/hook';
import { CURRENT_LANG, CURRENT_USER, TOKEN_USER, TOKEN_USER_ORI } from 'src/constants';
import React from "react";
import { Link } from "react-router-dom";

const useForceUpdate = () => {
  const set = useState(0)[1];
  return () => set((s) => s + 1);
};

const LoginWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(30)};
`
);

const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      <span>{label}</span>
    </Link>
  );
};

let callLogout = 1;
function NotAllowed() {
  const { REACT_APP_AUTHEMAILENABLED, REACT_APP_VERSION } = process.env;
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const forceUpdate = useForceUpdate();
  const dispatch = useAppDispatch();
  let idtoken = null;

  const validateForm = (value, isDirty: boolean = false) => {
    if (!isDirty) {
      setErrorText("");
      return false;
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.trim())) {
      setErrorText("");
      return true;
    }
    setErrorText(t('error.email'));
    return false;
  }

  const handleChangeEmail = e => {
    e.preventDefault();
    setEmail(e.target.value);
    validateForm(e.target.value);
  }
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (validateForm(email, true)) {
      authService.login(email, idtoken).then(response => {
        if (response.data.success) {
          localStorage.setItem(TOKEN_USER, response.data.data.token);
          localStorage.setItem(TOKEN_USER_ORI, null);
          localStorage.setItem(CURRENT_USER, JSON.stringify(response.data.data));
          localStorage.setItem(CURRENT_LANG, response.data.data.idLang === 1 ? 'fr' : 'en');
          i18n.changeLanguage(response.data.data.idLang === 1 ? 'fr' : 'en');

          const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER));
          if (userData.role.id > 2) {
            navigate("/page/management", { replace: true });
          } else {
            navigate("/page/accueil", { replace: true });
          }
        } else {
          setLoading(false);
          localStorage.removeItem(TOKEN_USER);
          localStorage.removeItem(TOKEN_USER_ORI);
          localStorage.removeItem(CURRENT_USER);
          localStorage.removeItem(CURRENT_LANG);
          setErrorText(t('error.invalidUser'));
        }
      }).catch(err => {
        setLoading(false);
      });
    } else setLoading(false);
  }

  useEffect(() => {
    const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER));
    if (userData !== null) {
      if (userData.role.id > 2) {
        navigate("/page/management", { replace: true });
      } else {
        navigate("/page/accueil", { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (idtoken != null) {
      callLogout = 1;
      // dispatch(authActions.login(email, idtoken));
      authService.login(email, idtoken).then(response => {
        if (response.data.success) {
          localStorage.setItem(CURRENT_USER, JSON.stringify(response.data.data));
          localStorage.setItem(TOKEN_USER_ORI, idtoken);
          localStorage.setItem(TOKEN_USER, response.data.data.token);
          localStorage.setItem(CURRENT_LANG, response.data.data.idLang === 1 ? 'fr' : 'en');
          i18n.changeLanguage(response.data.data.idLang === 1 ? 'fr' : 'en');

          const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER));
          if (userData.role.id > 2) {
            navigate("/page/management", { replace: true });
          } else {
            navigate("/page/accueil", { replace: true });
          }
        } else {
          setLoading(false);
          localStorage.removeItem(TOKEN_USER);
          localStorage.removeItem(TOKEN_USER_ORI);
          localStorage.removeItem(CURRENT_USER);
          localStorage.removeItem(CURRENT_LANG);
          setErrorText(t('error.userNotFound'));
        }
      }).catch(err => {
        setLoading(false);
      });
    }
  }, [email, idtoken]);

  const setAccount = (info) => {
    idtoken = info.jwtIdToken;
  }

  const signout = () => {
    callLogout = 0;
    dispatch(authActions.logout());
  }

  return (
    <LoginWrapper
      style={{
        backgroundImage: 'url(/static/images/tools/aste-skills-image-fond.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '100%'
      }}
    >
      {loading && (
        <>
          <SuspenseLoader />
        </>
      )}
      <Helmet>
        <title>My Skills</title>
      </Helmet>
      <Container maxWidth="lg" >
        <Box display="flex" justifyContent="center" py={5} alignItems="center" sx={{ marginTop: 15 }}>
          <Card sx={{ p: 5, width: 550 }} >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt="Ligne vert"
                  src="/static/images/logo/Tracé 41.svg"
                  style={{ height: 30 }}
                />
                <img
                  alt="Ligne vert"
                  src="/static/images/logo/Groupe 1.svg"
                  style={{ margin: 5, height: 21 }}
                />
              </Grid>
            </Grid>
            <Grid container
              direction="row"
              alignItems="center">
              <Grid item xs={8}>
                <TypographyH1 color="primary" sx={{ mb: 2, marginBottom: 0 }} variant="h1" >
                  MY SKILLS
                </TypographyH1>
              </Grid>
            </Grid>
            <Trans>mobileVersion</Trans>
          </Card>
        </Box>
      </Container>
    </LoginWrapper>
  );
}

export default NotAllowed;
