export enum AccessByRole {
  all = "all",
  read = "read",
  write = "write",
  readDc = "readDc",
  writeDc = "writeDc",
  readEa = "readEa",
  writeEa = "writeEa",
  updateDc = "updateDc",
  updateSubmittedDc = "updateSubmittedDc",
  updateValidatedDc = "updateValidatedDc",
  submitDc = "submitDc",
  validateDc = "validateDc",
  updateEa = "updateEa",
  updateValidatedEa = "updateValidatedEa"
}

export enum AccessPerPage {
  menuDc = "menuDc",
  ficheDc = "ficheDc",
  createDc = "createDc",
  menuEa = "menuEa",
  ficheEa = "ficheEa",
  menuManagement = "menuManagement",
  managementCollaborators = "managementCollaborators",
  managementCandidats = "managementCandidats",
  managementSkills = "managementSkills",
  managementAdministration = "managementAdministration",
  exportCertifications = "exportCertifications"
}