import { Suspense, lazy, ReactNode, Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { User } from './models/user/user.model';
import { userHasAccessToPage } from './utils/access';
import { CURRENT_LANG, CURRENT_USER, TOKEN_USER, TOKEN_USER_ORI } from './constants';
import { jwt } from './utils/jwt';
import ASMAlert from './components/Alert';
import { useTranslation } from 'react-i18next';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

function RolesAuthRoute({ children, page }: { children: ReactNode, page: string }) {
  console.log("caches ==> ", caches.keys())
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  const currentUser: User = JSON.parse(localStorage.getItem(CURRENT_USER)) || null;
  const isTokenExpired = jwt.isExpired();
  const { t } = useTranslation();
  if (isTokenExpired) {
    localStorage.removeItem(TOKEN_USER);
    localStorage.removeItem(TOKEN_USER_ORI);
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(CURRENT_LANG);
    return (
      <>
        <ASMAlert message={t('action.expiredSession')} severity="error" open={true} />
        <Navigate to="/" replace></Navigate>
      </>
    )
  }
  if (currentUser === null) {
    return (<Navigate to="/" replace></Navigate>)
  }
  const canAccess = userHasAccessToPage(page);
  if (canAccess) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Status403></Status403>
    </Fragment>
  );
}
// Pages
const Login = Loader(lazy(() => import('src/content/Login')));
const LoginOnBoarding = Loader(lazy(() => import('src/content/OnBoarding')));
const ResetOnBoarding = Loader(lazy(() => import('src/content/OnBoarding/reset')));

// Applications
const Competence = Loader(lazy(() => import('src/content/pages/Components/Competences')));
const ListeKP = Loader(lazy(() => import('src/content/pages/Components/ListeKP')));
const KeyPeople = Loader(lazy(() => import('src/content/pages/Components/ListeKP/KeyPeople')));
const ListSkills = Loader(lazy(() => import('src/content/pages/Components/ListSkills')));
const ListOtherSkills = Loader(lazy(() => import('src/content/pages/Components/ListOtherSkills')));
const ListeEEP = Loader(lazy(() => import('src/content/pages/Components/ListeEEP')));
const Candidat = Loader(lazy(() => import('src/content/pages/Components/Candidat')));
const Collaborateur = Loader(lazy(() => import('src/content/pages/Components/Collaborateurs')));
const FormationsCertifications = Loader(lazy(() => import('src/content/pages/Components/FormationsCertifications')));
const ExportCertifications = Loader(lazy(() => import('src/content/pages/Components/ExportCertifications')));
const ExportCompetences = Loader(lazy(() => import('src/content/pages/Components/ExportCompetences')));
const DroitDAcces = Loader(lazy(() => import('src/content/pages/Components/DroitDAcces')));

// Components
const Accueil = Loader(lazy(() => import('src/content/pages/Components/Accueil')));
const DCMenu = Loader(lazy(() => import('src/content/pages/Components/Accueil/DCMenu')));
const EAMenu = Loader(lazy(() => import('src/content/pages/Components/Accueil/EAMenu')));
const ManagerMenu = Loader(lazy(() => import('src/content/pages/Components/Accueil/ManagerMenu')));
const AdministrationMenu = Loader(lazy(() => import('src/content/pages/Components/Accueil/ManagerMenu/AdministrationMenu')));
const CreateDC = Loader(lazy(() => import('src/content/pages/Components/CreateDC')));
const FicheDC = Loader(lazy(() => import('src/content/pages/Components/FicheDC')));
const FicheEA = Loader(lazy(() => import('src/content/pages/Components/FicheEA')));

// Status
const Status403 = Loader(lazy(() => import('src/content/pages/Status/Status403')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const ASMDocument = Loader(lazy(() => import('src/components/ASMDocument')));

const {
  REACT_APP_DEV_ONBOARDINGURL,
  REACT_APP_PP_ONBOARDINGURL,
  REACT_APP_PROD_ONBOARDINGURL,
} = process.env;

const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;

const url = `${protocol}//${domain}${port ? ":" + port : ""}`;
const isOnBoarding = (url === REACT_APP_DEV_ONBOARDINGURL) || (url === REACT_APP_PP_ONBOARDINGURL) || (url === REACT_APP_PROD_ONBOARDINGURL);

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: isOnBoarding ? <LoginOnBoarding /> : <Login />
      },
      {
        path: '/reset',
        element: <ResetOnBoarding />
      },
      {
        path: 'status',
        element: (
          <SidebarLayout />
        ),
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '403',
            element: <Status403 />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'management',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <RolesAuthRoute page={'managementSkills'} >
            <Navigate
              to="/management/competence"
              replace
            />
          </RolesAuthRoute >
        )
      },
      {
        path: 'competence',
        element:
          <RolesAuthRoute page={'managementSkills'} >
            <Competence />
          </RolesAuthRoute >
      },
      {
        path: 'listeKP',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <ListeKP />
          </RolesAuthRoute >
      },
      {
        path: 'listSkills',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <ListSkills />
          </RolesAuthRoute >
      },
      {
        path: 'listOtherSkills',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <ListOtherSkills />
          </RolesAuthRoute >
      },
      {
        path: 'collaborateur',
        element:
          <RolesAuthRoute page={'managementCollaborators'} >
            <Collaborateur />
          </RolesAuthRoute >
      },
      {
        path: 'formationsCertifications',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <FormationsCertifications />
          </RolesAuthRoute >
      },
      {
        path: 'exportCertifications',
        element:
          <RolesAuthRoute page={'exportCertifications'} >
            <ExportCertifications />
          </RolesAuthRoute >
      },
      {
        path: 'exportCompetences',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <ExportCompetences />
          </RolesAuthRoute >
      },
      {
        path: 'droitDAcces',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <DroitDAcces />
          </RolesAuthRoute >
      },
      // key-people
      {
        path: 'key-people/:idUser',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <KeyPeople />
          </RolesAuthRoute >
      },
      {
        path: 'candidat',
        element:
          <RolesAuthRoute page={'managementCandidats'} >
            <Candidat />
          </RolesAuthRoute >
      },
      {
        path: 'listeEEP',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <ListeEEP />
          </RolesAuthRoute >
      },
      // 
    ]
  },
  {
    path: 'page',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'accueil',
        element:
          <RolesAuthRoute page={'menuDc'} >
            <Accueil />
          </RolesAuthRoute >
      },
      {
        path: 'dc/create/:idUser/:idLang',
        element:
          <RolesAuthRoute page={'createDc'} >
            <CreateDC />
          </RolesAuthRoute >
      },
      {
        path: 'dc/fiche/:id/:idLang',
        element:
          <RolesAuthRoute page={'ficheDc'} >
            <FicheDC />
          </RolesAuthRoute >
      },
      {
        path: 'ea/fiche/:year/:id',
        element:
          <RolesAuthRoute page={'ficheEa'} >
            <FicheEA />
          </RolesAuthRoute >
      },
      {
        path: 'dc/menu',
        element:
          <RolesAuthRoute page={'menuDc'} >
            <DCMenu />
          </RolesAuthRoute >
      },
      {
        path: 'ea/menu',
        element:
          <RolesAuthRoute page={'menuEa'} >
            <EAMenu />
          </RolesAuthRoute >
      },
      {
        path: 'management',
        element:
          <RolesAuthRoute page={'menuManagement'} >
            <ManagerMenu />
          </RolesAuthRoute >
      },
      {
        path: '/management/administration',
        element:
          <RolesAuthRoute page={'managementAdministration'} >
            <AdministrationMenu />
          </RolesAuthRoute >
      },
    ]
  },
  {
    path: 'document',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'pdf',
        element:
          <RolesAuthRoute page={'ficheEa'} >
            <ASMDocument />
          </RolesAuthRoute>
      }
    ]
  }
];

export default routes;
