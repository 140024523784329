import { commonService } from '.';

const addEducation = (body) => {
    return commonService.post('Education/add', body);
};

const getEducationByDc = (body) => {
    return commonService.getWithParam('Education/getByDc', body);
};

const deleteEducation = (id, body) => {
    return commonService.deleteWithParam('Education/' + id, body);
};

const updateEducation = (body) => {
    return commonService.put('Education/update', body);
};

export const educationService = {
    addEducation,
    getEducationByDc,
    deleteEducation,
    updateEducation
};
