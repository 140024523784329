import { commonService } from '.';

function hasAccess(body) {
  return commonService.post('FunctionnalityAccess/hasAccess', body);
}

function hasAccessToData(body) {
  return commonService.post('FunctionnalityAccess/hasAccessToData', body);
}

export const accessService = {
  hasAccess,
  hasAccessToData
};