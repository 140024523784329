import { FC, useState, createContext } from 'react';
import { eaService } from 'src/services';

type StatusEaContext = {
  idStatus: number;
  status: string;
  changeEAStatus: (newIdStatus) => void
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEaContext = createContext<StatusEaContext>(
  {} as StatusEaContext
);

export const StatusEaProvider: FC = ({ children }) => {
  const [idStatus, setIdStatus] = useState(0);
  const [status, setStatus] = useState('');

  const changeEAStatus = (newIdStatus) => {
    eaService.getStatusName(newIdStatus).then(response => {
      if (response.data.success) {
        setStatus(response.data.data)
      }
    });
    setIdStatus(newIdStatus);
  };

  return (
    <StatusEaContext.Provider value={{
      idStatus,
      status,
      changeEAStatus
    }}>
      {children}
    </StatusEaContext.Provider>
  );
};
