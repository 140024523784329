import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'src/redux/store';

import 'nprogress/nprogress.css';
import { ActionProvider } from './contexts/ActionContext';
import { EaProvider } from './contexts/EaContext';
import { StatusEaProvider } from './contexts/StatusEaContext';
import { SkillsBehaviorProvider } from './contexts/SkillsBehaviorContext';
import { SkillsLinguistiqueProvider } from './contexts/SkillsLinguistiqueContext';
import { SkillsTechnicalProvider } from './contexts/SkillsTechnicalContext';
import { SkillsMetierFonctionnelleProvider } from './contexts/SkillsMetierFonctionnelleContext';
import { SkillsManagerialeProvider } from './contexts/SkillsManagerialeContext';
import { KeyPeopleProvider } from './contexts/KeyPeopleContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';
// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <ActionProvider>
        <EaProvider>
          <SkillsBehaviorProvider>
            <SkillsLinguistiqueProvider>
              <SkillsTechnicalProvider>
                <SkillsMetierFonctionnelleProvider>
                  <SkillsManagerialeProvider>
                    <StatusEaProvider>
                      <KeyPeopleProvider>
                        <BrowserRouter>
                          <I18nextProvider i18n={i18n}>
                            <App />
                          </I18nextProvider>
                        </BrowserRouter>
                      </KeyPeopleProvider>
                    </StatusEaProvider>
                  </SkillsManagerialeProvider>
                </SkillsMetierFonctionnelleProvider>
              </SkillsTechnicalProvider>
            </SkillsLinguistiqueProvider>
          </SkillsBehaviorProvider>
        </EaProvider>
      </ActionProvider>
    </HelmetProvider>
  </Provider>
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
