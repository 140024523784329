import { commonService } from '.';
import axios from "axios";
// postAsync
const APIURL = process.env.PUBLIC_URL + "/api";

const getEaDetails = (body) => {
  return commonService.getWithParam('Ea/details', body);
};
const getYears = (body) => {
  return commonService.getWithParam('Ea/years', body);
}

const updateEa = (body) => {
  return commonService.post('Ea/save', body);
};

const updateEaStatus = (id, action, idUserConnected) => {
  return commonService.put('Ea/status?id=' + id + '&action=' + action + '&idUserConnected=' + idUserConnected, null);
};

const getStatusName = (id, idLang = 1) => {
  return commonService.getWithParam('Ea/status?id=' + id + '&idLang=' + idLang, null);
};
const downloadEA = (body) => {
  return commonService.getWithParam('SpireDoc/downLoadEA', body);
};

const managerSigned = (idEa, idUserConnected) => {
  return commonService.put('Ea/managerSigned?idEa=' + idEa + '&idUserConnected=' + idUserConnected, null);
}

const sendMailEEP = (body, idLang) => {
  return commonService.post('Ea/sendEmailEEP?idLang=' + idLang, body);
}

const updateEaAsync = async (body) => {
  const endpoint = 'Ea/save';
  const url = `${APIURL}/` + endpoint;
  return await axios.post(url, body);
};

export const eaService = {
  getEaDetails,
  getYears,
  updateEa,
  updateEaStatus,
  getStatusName,
  downloadEA,
  managerSigned,
  sendMailEEP,
  updateEaAsync
};
