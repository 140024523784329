import { commonService } from '.';

const getTrainingParId = (body) => {
    return commonService.getWithParam('Training/trainingById', body);
};

const insertWishTrainingData = (body) => {
    return commonService.post('Training/wishTraining', body);
}

const updateWishTrainingData = (id, body) => {
    return commonService.post('Training/wishTraining?id=' + id, body);
}

const insertTrainingFormations = (id, body) => {
    return commonService.post('Training/insertTraining?id=' + id, body);
}

// const updateTrainingFormations = (id, body) => {
//     return commonService.post('Training/insertTraining?id=' + id, body);
// }

const deleteTraining = (id) => {
    return commonService.deleteWithParam('Training/wishTraining?id=' + id);
};

const updateTrainingReportData = (id, body) => {
    return commonService.put('Training/trainingReport?id=' + id, body);
}

const updateCertificationAchievedData = (id, file, body) => {
    return commonService.put('Training/certificationAchieved?id=' + id, file, body);
}

export const training = {
    getTrainingParId,
    insertWishTrainingData,
    insertTrainingFormations,
    updateWishTrainingData,
    // updateTrainingFormations,
    deleteTraining,
    updateTrainingReportData,
    updateCertificationAchievedData
};
