import { commonService } from '.';

const addExperience = (body) => {
    return commonService.post('Experience/add', body);
};

const getExperienceByDc = (body) => {
    return commonService.getWithParam('Experience/getByDc', body);
};

const deleteExperience = (id, body) => {
    return commonService.deleteWithParam('Experience/' + id, body);
};

const updateExperience = (body) => {
    return commonService.put('Experience/update', body);
};

export const experienceService = {
    addExperience,
    getExperienceByDc,
    deleteExperience,
    updateExperience
};
