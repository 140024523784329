import { commonService } from '.';

const getUserNoKp = (body) => {
    return commonService.getWithParam('KeyPeople/userNoKp', body);
}

const getKeyPeopleData = (body) => {
    return commonService.getWithParam('KeyPeople', body);
}

const updateKeyPeopleData = (idUserConnected, body) => {
    return commonService.put('KeyPeople?idUserConnected=' + idUserConnected, body);
};

export const keyPeopleService = {
    getUserNoKp,
    getKeyPeopleData,
    updateKeyPeopleData
};
