import { commonService } from '.';

const addCertificationTest = (body) => {
    return commonService.post('CertificationTest/add', body);
};

const getCertificationTestByDc = (body) => {
    return commonService.getWithParam('CertificationTest/getByDc', body);
};

const deleteCertificationTest = (id, body) => {
    return commonService.deleteWithParam('CertificationTest/' + id, body);
};

const updateCertificationTest = (body) => {
    return commonService.put('CertificationTest/update', body);
};

const addCertificationSkill = (body) => {
    return commonService.post('CertificationTest/addCertificationSkill', body);
};

export const certificationTestService = {
    addCertificationTest,
    getCertificationTestByDc,
    deleteCertificationTest,
    updateCertificationTest,
    addCertificationSkill
};
