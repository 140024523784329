import { FC, useState, createContext } from 'react';
import { skillsEvaluation, boondService } from 'src/services';
import { SkillEvaluation } from 'src/models/skill/skillEvaluation.model';
import { useTranslation } from 'react-i18next';
import ASMAlert from 'src/components/Alert';
import { CURRENT_USER } from 'src/constants';
import { useContext } from 'react';
import { EaContext } from 'src/contexts/EaContext';

type SkillsTechnicalContext = {
	setSkillsTechnical: () => any;
	setSkillsTechnicalDataContext: (dataValue) => void;
	skillsTechnicalDataKO: boolean;
	setSkillsTechnicalDataKOContext: (dataValue) => void;
	skillsTechnicalData: SkillEvaluation[];
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkillsTechnicalContext = createContext<SkillsTechnicalContext>(
	{} as SkillsTechnicalContext
);

export const SkillsTechnicalProvider: FC = ({ children }) => {
	const {
		idUser
	} = useContext(EaContext);
	const { t } = useTranslation();
	const { REACT_APP_NOTIFICATION_DURATION } = process.env;
	const [skillsTechnicalData, setSkillsTechnicalData] = useState<SkillEvaluation[]>(null);
	const [displayAlert, setDisplayAlert] = useState<boolean>(false);
	const [displayAlertError, setDisplayAlertError] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string>(t('action.success'));
	const [errorMessage, setErrorMessage] = useState<string>(t('action.error'));
	const userData = JSON.parse(localStorage.getItem(CURRENT_USER));
	// check data
	const [skillsTechnicalDataKO, setSkillsTechnicalDataKO] = useState<boolean>(false);
	// 

	const setSkillsTechnicalDataKOContext = (dataValue) => {
		setSkillsTechnicalDataKO(dataValue);
	};
	const setSkillsTechnicalDataContext = (dataValue) => {
		// check data
		if (dataValue == null || dataValue.length == 0) {
			setSkillsTechnicalDataKO(false);
		}
		// 
		setSkillsTechnicalData(dataValue);
		// check data
		for (let i = 0; i < dataValue.length; i++) {
			if (idUser != 0) {
				if (idUser == userData.id) { // consultant
					if (dataValue[i].noteConsultant == 0 || dataValue[i].noteConsultant == null) {
						setSkillsTechnicalDataKO(true);
						break;
					} else {
						setSkillsTechnicalDataKO(false);
					}
				} else { // manager
					if (dataValue[i].noteManager == 0 || dataValue[i].noteManager == null) {
						setSkillsTechnicalDataKO(true);
						break;
					} else {
						setSkillsTechnicalDataKO(false);
					}
				}
			}
		}
	};

	const setSkillsTechnical = async () => {
		let errorMessage = "";
		if (skillsTechnicalData != null) {
			await skillsEvaluation.updateALotTechnicalSkillsEvaluationAsync(skillsTechnicalData).then(response => {
				if (!response.data.success) {
					errorMessage = '- ' + response.data.message;
				}
			}).catch(err => {
				errorMessage = '- ' + err.response.data.message;
			});
		}
		return errorMessage;
	}

	return (
		<>
			<ASMAlert message={successMessage} severity="success" open={displayAlert}
				withClose
				handleCloseAlert={() => {
					setDisplayAlert(false)
				}}
			/>
			<ASMAlert message={errorMessage} severity="error" open={displayAlertError}
				withClose
				handleCloseAlert={() => {
					setDisplayAlertError(false)
				}}
			/>
			<SkillsTechnicalContext.Provider value={{
				setSkillsTechnical,
				setSkillsTechnicalDataContext,
				skillsTechnicalDataKO,
				setSkillsTechnicalDataKOContext,
				skillsTechnicalData
			}}>
				{children}
			</SkillsTechnicalContext.Provider>
		</>
	);
};
