import { FC, useState, createContext } from 'react';
import { skillsEvaluation } from 'src/services';
import { SkillEvaluation } from 'src/models/skill/skillEvaluation.model';
import { useTranslation } from 'react-i18next';
import { CURRENT_USER } from 'src/constants';
import { useContext } from 'react';
import { EaContext } from 'src/contexts/EaContext';

type SkillsManagerialeContext = {
    setSkillsManageriale: () => any;
    setSkillsManagerialeDataContext: (dataValue) => void;
    skillsManagerialeDataKO: boolean;
    setSkillsManagerialeDataKOContext: (dataValue) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkillsManagerialeContext = createContext<SkillsManagerialeContext>(
    {} as SkillsManagerialeContext
);

export const SkillsManagerialeProvider: FC = ({ children }) => {
    const {
        idUser
    } = useContext(EaContext);
    const userData = JSON.parse(localStorage.getItem(CURRENT_USER));
    const [skillsManagerialeData, setSkillsManagerialeData] = useState<SkillEvaluation[]>(null);
    // check data
    const [skillsManagerialeDataKO, setSkillsManagerialeDataKO] = useState<boolean>(false);
    const setSkillsManagerialeDataKOContext = (dataValue) => {
        setSkillsManagerialeDataKO(dataValue);
    };
    const setSkillsManagerialeDataContext = (dataValue) => {
        // check data temp
        if (dataValue == null || dataValue.length == 0) {
            setSkillsManagerialeDataKO(false);
        }
        // 
        setSkillsManagerialeData(dataValue);
        // check data
        for (let i = 0; i < dataValue.length; i++) {
            if (idUser != 0) {
                if (idUser == userData.id) { // consultant
                    if (dataValue[i].noteConsultant == 0 || dataValue[i].noteConsultant == null) {
                        setSkillsManagerialeDataKO(true);
                        break;
                    } else {
                        setSkillsManagerialeDataKO(false);
                    }
                } else { // manager
                    if (dataValue[i].noteManager == 0 || dataValue[i].noteManager == null) {
                        setSkillsManagerialeDataKO(true);
                        break;
                    } else {
                        setSkillsManagerialeDataKO(false);
                    }
                }
            }
        }
    };
    const setSkillsManageriale = async () => {
        let errorMessage = "";
        if (skillsManagerialeData != null) {
            await skillsEvaluation.updateALotSkillsEvaluationAsync(skillsManagerialeData).then(response => {
                if (!response.data.success) {
                    errorMessage = '- ' + response.data.message;
                }
            }).catch(err => {
                errorMessage = '- ' + err.response.data.message;
            });
        }
        return errorMessage;
    }
    const { t } = useTranslation();
    return (
        <>
            <SkillsManagerialeContext.Provider value={{
                setSkillsManageriale,
                setSkillsManagerialeDataContext,
                skillsManagerialeDataKO,
                setSkillsManagerialeDataKOContext
            }}>
                {children}
            </SkillsManagerialeContext.Provider>
        </>
    );
};
