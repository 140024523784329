// Sessions variable's name
export const TOKEN_USER = "TOKEN_USER";
export const TOKEN_USER_ORI = "TOKEN_USER_ORI";
export const CURRENT_USER = "user";
export const CURRENT_LANG = "lan";
export const APPCONFIG = "APPCONFIG";
export const STRUCTURE = "STR";
export const CONSULTANT = "CLT";
export const VIP = "VIP";

export const authActionsType = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'LOGOUT'
};

export const alertActionsType = {
    SUCCESS: 'ALERT_SUCCESS',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    ERRORBO: 'ALERT_ERRORBO',
    CLEAR: 'ALERT_CLEAR'
};