import { commonService } from '.';

const updateGoal = (id, body) => {
    return commonService.post('Goal?id=' + id, body);
};

const insertGoal = (body) => {
    return commonService.post('Goal', body);
};

const deleteGoal = (id) => {
    return commonService.deleteWithParam('Goal?id=' + id, null);
};

export const goalService = {
    updateGoal,
    insertGoal,
    deleteGoal
};