import { commonService } from '.';

const getAllLevel = (body) => {
    return commonService.getWithParam('Language/levels', body);
};

const addLanguage = (body) => {
    return commonService.post('Language/add', body);
};

const getLanguageByDc = (body) => {
    return commonService.getWithParam('Language/getByDc', body);
};

const deleteLanguage = (id, body) => {
    return commonService.deleteWithParam('Language/' + id, body);
};

const updateLanguage = (body) => {
    return commonService.put('Language/update', body);
};

export const languageService = {
    getAllLevel,
    addLanguage,
    getLanguageByDc,
    deleteLanguage,
    updateLanguage
};
